var $ = require("jquery");
var slick = require("slick-carousel");
import { gsap } from "gsap";
import Flickity from 'flickity';


import ModalVideo from 'modal-video';

$(document).ready(function() {

  let x = $('.client-icons-block').width();

  document.querySelectorAll('.client-icons-block').forEach(function(icons, index) {

    var children = icons.children;
    var totalWidth = 0;

    for (var i = 0; i < children.length; i++) {
      totalWidth += children[i].offsetWidth;
    }
    console.log(totalWidth / 4);


    gsap.to(icons, {
      duration: 10 / 788 * totalWidth / 4,
      ease: "none",
      x: "-=" + totalWidth / 4, //move each box 500px to right
      repeat: -1
    });

  })

  if (document.querySelector('#testimonials-video') !== null) {
    var flky = new Flickity('#testimonials-video', {
      // options, defaults listed
      draggable: false
    });
  }


  $('#customer-stories').slick({
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    appendArrows: '.customer-stories .carousel-nav',
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>',
    nextArrow: '<button type="button" class="slick-next"><span></span></button>'
  });


  $('.related-blogs-block').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    variableWidth: true,
    appendArrows: '.related-blogs .carousel-nav',
    prevArrow: '<button type="button" class="slick-prev"><span></span></button>',
    nextArrow: '<button type="button" class="slick-next"><span></span></button>',
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          infinite: false,
          centerPadding: '0px'
        }
      }
    ]
  });


  new ModalVideo('.js-modal-btn');

  $('.faq-block .question').on('click', function() {
    $(this).closest('.faq-block').toggleClass('active');
  });

  $('.map-address li').on('click', function() {
    $('.map-address li').removeClass('active');
    $(this).addClass('active');
    $('.map-address-container').removeClass('active');
    $('.map-address-container[data-google-map="' + $(this).data('google-map') + '"]').addClass('active');
  });


  $('.js--see-more-seo').on('click', function() {
    let $parent = $(this).parents('.c--texte-seo');
    let moreText = $(this).data('more');
    let lessText = $(this).data('less');
    if ($parent.hasClass('open')) {
      $parent.removeClass('open');
      $(this)[0].innerHTML = moreText;
    } else {
      $parent.addClass('open');
      $(this)[0].innerHTML = lessText;
    }

  })


});

document.getElementById('nav_btn').addEventListener("click", function(e) {
  e.preventDefault();
  var PageContainer = document.getElementById("page-container");
  PageContainer.classList.toggle("active-state");
  var MainNav = document.getElementById("main-navigation");
  MainNav.classList.toggle("active-state");
  this.classList.toggle("active");
});


function Tabs() {
  var bindAll = function() {
    var menuElements = document.querySelectorAll('[data-tab]');
    for (var i = 0; i < menuElements.length; i++) {
      menuElements[i].addEventListener('click', change, false);
    }
  }

  var clear = function() {
    var menuElements = document.querySelectorAll('[data-tab]');
    for (var i = 0; i < menuElements.length; i++) {
      menuElements[i].classList.remove('active');
      var id = menuElements[i].getAttribute('data-tab');
      document.getElementById(id).classList.remove('active');
    }
  }

  var change = function(e) {
    e.preventDefault();
    clear();
    e.target.classList.add('active');
    var id = e.currentTarget.getAttribute('data-tab');
    document.getElementById(id).classList.add('active');
  }

  bindAll();
}

var connectTabs = new Tabs();